*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace, monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace, monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  background-color: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: Sligoil Micro;
  src: url("Sligoil-Micro.30fba1b3.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  text-transform: uppercase;
  color: #fff;
  background-color: #000;
  margin: 15px;
  font-family: Sligoil Micro;
  font-size: min(max(15.5px, 4vw), 28px);
  line-height: 1.15;
}

:root {
  --border-color: cyan;
}

@media (min-width: 600px) {
  body {
    margin: 30px;
  }
}

@media (min-width: 730px) {
  body {
    font-size: min(max(20px, 2vw), 24px);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  border-bottom: 1px solid;
  border-color: var(--border-color);
  text-decoration: none;
}

p {
  margin: 0;
}

.ma-work {
  width: 35%;
  pointer-events: none;
  display: none;
  position: absolute;
}

.ma-work img {
  width: 100%;
  height: auto;
  border: 1px solid var(--border-color);
}

@media (min-width: 600px) {
  .ma-work {
    display: block;
  }
}

.contacts {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

@media (min-width: 600px) {
  .contacts {
    bottom: 30px;
    left: 30px;
  }
}

.hidden-images {
  display: none;
}

/*# sourceMappingURL=index.a7f050dc.css.map */
