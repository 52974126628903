body {
  margin: 15px;
  font-family: "Sligoil Micro";
  font-size: min(max(15.5px, 4vw), 28px);
  line-height: 1.15;
  text-transform: uppercase;
  background-color: black;
  color: white;
}

:root {
  --border-color: cyan;
}

@media (min-width: 600px) {
  body {
    margin: 30px;
  }
}

@media (min-width: 730px) {
  body {
    font-size: min(max(20px, 2vw), 24px);
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    border-bottom: 1px solid;
    border-color: var(--border-color);
  }
}

p {
  margin: 0;
}


.ma-work {
  width: 35%;
  position: absolute;
  pointer-events: none;
  display: none;

  img {
    width: 100%;
    height: auto;
    border: 1px solid var(--border-color);
  }
}

@media (min-width: 600px) {
  .ma-work {
    display: block;
  }
}

.contacts {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
@media (min-width: 600px) {
  .contacts {
    bottom: 30px;
    left: 30px;
  }
}


.hidden-images {
  display: none;
}
